import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';

import { UserProfileDialogComponent } from '@modules/auth/user-profile-dialog/user-profile-dialog.component';
import { SignupFormComponent } from '@modules/person-profiles/signup-form/signup-form.component';
import { AuthService } from '@modules/auth/auth.service';
import { ConfigService } from '@services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('drawer') drawer: MatSidenav;
  title: string = 'Loading...';
  showFiller: boolean = false;
  isMenuCollapsed: Boolean = false;
  showNavBar = true;
  links: any[];
  menu: any[] = [
    {label: "Loading Menu...", path: "/"}
  ];
  
  constructor(public dialog: MatDialog,
              public authSvc: AuthService,
              private configSvc: ConfigService,
              private titleSvc: Title,
              private route: ActivatedRoute,
              private router: Router
              ) {
    this.configSvc.settings.pipe(
      filter((settings) => settings.title)
    ).subscribe((settings: any) => this.titleSvc.setTitle(settings.title));
    combineLatest(this.authSvc.currentUser$, this.configSvc.settings).pipe(
      filter(([currentUser, settings]) => settings.userGroups)
    ).subscribe(([currentUser, settings]) => {
      this.links = [
        {label: "Activists & Donors", path: "/activists/new", groupPermissions: settings.userGroups},
     //   {label: "List Manager", path: "/list-manager", groupPermissions: settings.userGroups},
     //   {label: "File Importer", path: "/import-files", groupPermissions: ["Administrator"]},
        {label: "E-mail Editor", path: "/email-editor", groupPermissions: ["Administrator"]},
        {label: "Activist Dashboard", path: "/dashboard", groupPermissions: ["Administrator"]},
        {label: "Activist Notes", path: "/notes", groupPermissions: ["Administrator"]},
     //   {label: "Donor Dashboard", path: "/donors", groupPermissions: ["Administrator"]},
     //   {label: "Gift Dashboard", path: "/gift-dashboard", groupPermissions: ["Administrator"]},
        {label: "Annual Planning", path: "/annual-planning", groupPermissions: ["Administrator"]},
     //   {label: "Solicitor Dashboard", path: "/solicitor-dashboard", groupPermissions: ["Administrator"]},
     //   {label: "Deduplicator", path: "/deduplicator", groupPermissions: ["Administrator"]},
     //   {label: "Importer", path: "/import", groupPermissions: ["Administrator"]},
        {label: "Users", path: "/users", groupPermissions: ["Administrator"]},
      ];
      if (this.authSvc.isLoggedIn()) {
        this.menu = this.links.filter(link => link.groupPermissions.includes(currentUser.user_group));
      }
    });
    router.events.subscribe(() => this.drawer.close());
  }
  
  userProfileDialog = () => {
    let dialogRef = this.dialog.open(UserProfileDialogComponent, {position: {top: "70px", right: "30px"}});
  }
  onActivate = (event) => {
    this.showNavBar = !(event instanceof SignupFormComponent);
  }
}