import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { BehaviorSubject, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom, distinctUntilChanged, skip } from 'rxjs/operators';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as moment from "moment";

import { PeopleService } from '../people.service';
import { AuthService } from '@modules/auth/auth.service';
import { DialogService } from '@modules/dialogs/dialog.service';
import { UtilityService } from '@services/utility.service';
import { ListService } from '@modules/list-management/list.service';
import { ConfigService } from '@services/config.service';
import { PersonFilterListComponent } from '../person-filter-list/person-filter-list.component';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit, AfterViewInit {
  @Input('person') person$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Output() changePerson = new EventEmitter<any>();
  @ViewChild('filterList') filterList: PersonFilterListComponent;
  callLink: string = 'www.slingofdavid.com/call-sign-form?recruiter=';
  
  // NGIF VARIABLES ***************************************************************
  disableSave: boolean = false;
  showList: boolean = true;
  showNewAddress: boolean = false;
  showNewPhone: boolean = false;
  showNewEmail: boolean = false;
  showReports: boolean = false;
  displayMode: string = "view";
  showNewListForm: boolean = false;
  
  getOpens = (message: any) => {
    return message.responses.filter((msg: any) => msg.eventType === 'Open').length;
  }
  
  // VIEW PROPERTIES *************************************************************
  viewWidth: number = window.innerWidth;
  activePanes: string[] = ["General", "Activism", "Email Messages", "Donations", "Contact Log"];
  activePane: string = this.activePanes[0];
  personLoading: boolean = false;
  
  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              public peopleSvc: PeopleService,
              public authSvc: AuthService,
              private dialogSvc: DialogService,
              public utilitySvc: UtilityService,
              public listSvc: ListService,
              private configSvc: ConfigService
              ) { }

  ngOnInit(): void {
    this.authSvc.currentUser$.subscribe((user: any) => {
      console.log("CURRENT USER: ", user);
    });
    this.route.data.subscribe((data: any) => {
      this.person$.next(data.person);
    });
    this.route.paramMap.subscribe(params => {
      console.log("NAVIGATED ROUTER");
      this.personLoading = true;
      console.log("PERSON LOADING: ", this.personLoading);
      if (params.get('id') === "new") {
        this.displayMode = "edit";
        this.person$.next({});
      } else {
        this.displayMode = "view";
        this.disableSave = false;
      }
    });
    if (this.viewWidth < 768) this.activePane = "contact";
    this.person$.subscribe((person: any) => {
      this.personLoading = false;
      console.log("PERSON LOADING: ", this.personLoading);
    });
  }
  ngAfterViewInit() {
    this.filterList.selectedIndex$.pipe(
      skip(2),
      distinctUntilChanged()
    ).subscribe((index: number) => {
      // this.personLoading = true;
    });
  }
  
  navigateToPerson = (id: number) => {
    this.personLoading = false;
    this.router.navigate(['activists', id]);
  }
  toggleEmailMute = (person: any) => {
    let update: any = {
      person_id: person.person_id,
      mute_email: person.mute_email===0?1:0
    }
    
    this.peopleSvc.updatePerson(update).subscribe((response: any) => this.person$.next(response));
  }
  personUpdated = (person: any) => {
    let filter: any = this.filterList.filter$.getValue();
    filter.currentIndex = false;
    this.person$.next(person);
    this.filterList.filter$.next(filter);
  }
  deletePerson = (person: any) => {
    let title: string = "Confirm Delete",
        msg: string = `Are you sure you want to delete '${this.peopleSvc.getPersonName(person)}'?`;
    
    this.dialogSvc.confirmDialog(title, msg).pipe(
      filter((confirm: boolean) => confirm),
      switchMap(() => this.peopleSvc.deletePerson(person.person_id)),
      withLatestFrom(this.filterList.filter$)
    )
    .subscribe(([response, filter]: [any, any]) => {
      filter.currentIndex = true;
      this.filterList.filter$.next(filter);
    });
  }
  
  // VERIFICATION FUNCTIONS *********************************************************************
  returnAuthUser = () => {
    if (this.authSvc.getCurrentUserName()) return this.authSvc.getCurrentUserName().toUpperCase();
    return "";
  }
  toggleVerificationStatus = (id: number, person: any) => {
    let status: number = person.verified === 1?0:1,
        updateDate: Date = new Date(),
        formattedDate: string = moment(updateDate).format("YYYY-MM-DD HH:mm:ss"),
        updateUser: string = this.authSvc.getCurrentUserName(),
        verifyMsg: string = `${updateUser} successfully VERIFIED ${this.peopleSvc.getPersonName(person)} at ${moment(updateDate).format('H:mm a')} on ${moment(updateDate).format('dddd, MMMM Do, YYYY')}`,
        unverifyMsg: string = `${updateDate} successfully UNVERIFIED ${this.peopleSvc.getPersonName(person)} at ${moment(updateDate).format('H:mm a')} on ${moment(updateDate).format('dddd, MMMM Do, YYYY')}`,
        update = {
          verified: status,
          date_verified: formattedDate,
          user_verified: updateUser
        };
        
    this.peopleSvc.toggleVerificationStatus(id, update)
    .subscribe((response: any) => {
      let msg: string = status === 1?verifyMsg:unverifyMsg;
      
      this.person$.next(response);
      this._snackBar.open(msg, 'X', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000})
    });
  }
  getVerificationTitle = (person: any) => {
    let settings: any = this.configSvc.settings.getValue(),
        verifyMsg: string = `${this.peopleSvc.getPersonName(person)} VERIFIED on ${person.date_verified} by ${person.user_verified}`,
        unverifyMsg: string =  `Click here to VERIFY ${this.peopleSvc.getPersonName(person)} as authorized ${settings.applicationTitle} User ${this.returnAuthUser()}`
    
    return person.person_id?(person.verified === 1?verifyMsg:unverifyMsg):"";
  }
  
  displayNull = () => {
    return null;
  }
}