import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  url: string = "https://slingofdavid.com:3000/dashboards"
  
  constructor(private http: HttpClient) { }
  
  getDashboardData = (filter: any) => {
    return this.http.get(this.url + "/data", {params: filter});
  }
  getActivistMetricReport = (filter: any) => {
    return this.http.get(this.url + "/report", {params: filter, responseType: 'blob'});
  }
}