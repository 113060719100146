<div fxFlexFill fxLayout="column" *ngIf="filter$ | async as filter">
  <ng-container *ngIf="person$ | async as selectedPerson">
    <div fxLayout="row" fxLayoutAlign="space-between center"
         *ngIf="configSvc.settings | async as configSettings">
      <button mat-icon-button color="primary"
              title="Filter By List" [matMenuTriggerFor]="listMenu">
        <mat-icon>label</mat-icon>
      </button>
      <mat-menu #listMenu="matMenu">
        <button mat-menu-item (click)="showAll(filter)">
          <mat-icon *ngIf="filter.list.list_id === -1">done</mat-icon>
          <span class="sm-menu-itm ellipsis" [ngClass]="{'indented': filter.list.list_id !== -1}">All Entries</span>
        </button>
        <button mat-menu-item (click)="toggleList(list, filter)"
                *ngFor="let list of listSvc.lists | async" class="ellipsis">
          <mat-icon *ngIf="filter.list.list_id === list.list_id">done</mat-icon>
          <span class="sm-menu-itm ellipsis"
                [ngClass]="{'indented': filter.list.list_id !== list.list_id}">{{list.list}}</span>
        </button>
      </mat-menu>
      <button mat-icon-button color="primary" title="Filter By State"
              [matMenuTriggerFor]="stateMenu">
        <mat-icon>public</mat-icon>
      </button>
      <mat-menu #stateMenu="matMenu">
        <button mat-menu-item (click)="toggleState(state.abbreviation, filter)"
                *ngFor="let state of regionSvc.states">
          <mat-icon *ngIf="filter.states.indexOf(state.abbreviation) > -1">done</mat-icon>
          <span [ngClass]="{'indented': filter.states.indexOf(state.abbreviation) === -1}">{{state.name}}</span>
        </button>
      </mat-menu>
      <button mat-icon-button color="warn" title="Clear Filter Criteria" (click)="clearFilter()"
              [disabled]="!filter.list.list_id && filter.states.length === 0">
        <mat-icon>clear</mat-icon>
      </button>
      <button mat-icon-button title="Download List Files"
              color="primary"
              [matMenuTriggerFor]="downloadMenu">
        <mat-icon>download</mat-icon>
      </button>
      <mat-menu #downloadMenu="matMenu">
        <button *ngIf="((listItems$ | async) || []).length > 0" mat-menu-item
                (click)="downloadFilterList(filter)">
          <span>Download Active Filter List</span>
        </button>
        <button mat-menu-item (click)="downloadDonorReport()">
          <span>Download 1K Donor List</span>
        </button>
        <button *ngIf="configSettings.title === 'Center for Renewing America'"
                mat-menu-item (click)="downloadCallList()">
          <span>Download Call List</span>
        </button>
        <button *ngIf="configSettings.title === 'Center for Renewing America'"
                mat-menu-item (click)="downloadEmailList()">
          <span>Download Email Notes List</span>
        </button>
        <mat-divider *ngIf="configSettings.title === 'Center for Renewing America' || filter.list.list_id"></mat-divider>
        <button mat-menu-item (click)="downloadNoStateReport()">
          <span>Download No State Report</span>
        </button>
        <mat-divider *ngIf="configSettings.title === 'Center for Renewing America' || filter.list.list_id"></mat-divider>
        <button *ngFor="let list of listSvc.lists | async" mat-menu-item
                (click)="downloadFilterList(list.list_id, list.list)">
          <span>Download {{list.list}}</span>
        </button>
      </mat-menu>
    </div>
    <div class="filter-conditions" *ngIf="filter.list.list_id || filter.states.length > 0">
      <div>
        <span class="ellipsis" style="font-weight: bold; text-decoration: underline;">Filter Conditions</span>
      </div>
      <div>
        <span *ngIf="filter.list.list_id"> <span style="font-weight: bold;">List: </span>{{filter.list.list}}</span>
      </div>
      <div>
        <span *ngIf="filter.states.length"> <span style="font-weight: bold;">States: </span> {{stateFilterDisplay(filter.states)}}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex>
        <div>
          <span class="bold">Rows Per Page:&nbsp;</span>
          <span class="pointer" [matMenuTriggerFor]="filterPageSizeMenu">{{filter.pageSize}}</span>
          <mat-menu #filterPageSizeMenu="matMenu">
            <button mat-menu-item *ngFor="let pageSize of pageSizeOptions"
                    (click)="changePageSize(filter, pageSize)">
              <span>{{pageSize}}</span>
            </button>
          </mat-menu>
        </div>
        <div>
          <span class="bold">Total Rows:&nbsp;</span>
          <span>{{totalRecords$ | async | number: '1.0'}}</span>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start end">
        <div>
          <span>Rows {{(filter.pageIndex * filter.pageSize) + 1}}</span>
          <span>-{{(((filter.pageIndex + 1) * filter.pageSize) > (totalRecords$ | async))?(totalRecords$ | async):((filter.pageIndex + 1) * filter.pageSize) | number: '1.0'}}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start end" class="ellipsis"
             (wheel)="scrollPage($event, filter)">
          <button mat-icon-button class="sm-icon-btn" [disabled]="filter.pageIndex === 0"
                  (click)="changePage(filter, -1, 0)">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <span>Page {{filter.pageIndex + 1}} of {{totalPages}}</span>
          <button mat-icon-button class="sm-icon-btn" [disabled]="(filter.pageIndex + 1) >= totalPages"
                  (click)="changePage(filter, 1, 0)">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="loading">
      <span>Loading...</span>
    </div>
    <ng-container *ngIf="!loading">
      <ul fxFlex class="overflow-auto" *ngIf="{index: selectedIndex$ | async} as selected"
          (wheel)="scroll($event, selected.index, filter)">
        <li *ngFor="let person of listItems$ | async; index as i;" style="min-height: 20px;"
            [ngClass]="{'selected': i === selected.index}" (click)="selectedIndex$.next(i)">
            <span class="ellipsis" [title]="getPersonName(person)">{{getPersonName(person)}}</span>
        </li>
      </ul>
    </ng-container>
  </ng-container>
</div>