import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxMaskModule } from 'ngx-mask';

import { SharedComponentsModule } from '@modules/shared-components/shared-components.module';

import { DonationDialogComponent } from './donation-dialog/donation-dialog.component';
import { DonationsComponent } from './donations/donations.component';
import { DonationsTableComponent } from './donations-table/donations-table.component';
import { DonorsComponent } from './donors/donors.component';
import { DonorDashboardComponent } from './donor-dashboard/donor-dashboard.component';
import { GiftDashboardComponent } from './gift-dashboard/gift-dashboard.component';
import { AnnualPlanningComponent } from './annual-planning/annual-planning.component';
import { AnnualPlanningNotesTableCellComponent } from './annual-planning-notes-table-cell/annual-planning-notes-table-cell.component';
import { AnnualPlanningTableComponent } from './annual-planning-table/annual-planning-table.component';

@NgModule({
  declarations: [
    DonationDialogComponent,
    DonationsComponent,
    DonationsTableComponent,
    DonorsComponent,
    DonorDashboardComponent,
    GiftDashboardComponent,
    AnnualPlanningComponent,
    AnnualPlanningNotesTableCellComponent,
    AnnualPlanningTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    NgxMaskModule,
    DragDropModule,
    SharedComponentsModule,
  ],
  exports: [
    DonationDialogComponent,
    DonationsTableComponent,
    DonorDashboardComponent,
    GiftDashboardComponent,
    AnnualPlanningComponent
  ]
})
export class DonorManagementModule { }
