<div class="container-fluid h-100 d-flex flex-column">
  <div class="row flex-grow-1 flex-shrink-1 overflow-auto">
    <div class="col-12 flex-grow-1 flex-shrink-1 overflow-auto">
      <div class="overflow-auto preview-frame" [innerHtml]="entryData.html_content | safeHtml"></div>
    </div>
  </div>
  <div class="row">
    <div mat-dialog-actions class="col-12 d-flex justify-content-end">
      <button mat-flat-button mat-dialog-close color="primary">Close</button>
    </div>
  </div>
</div>