<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
     [formGroup]="filterForm">
  <mat-form-field>
    <mat-label>Name Search</mat-label>
    <input matInput type="text" formControlName="name">
    <button mat-icon-button matSuffix (click)="filterForm.get('name').reset()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Set Date Paid Range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <button mat-icon-button matSuffix (click)="filterForm.get('start').reset(); filterForm.get('end').reset()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Min Donation</mat-label>
    <input matInput type="number" formControlName="min">
    <button mat-icon-button matSuffix (click)="filterForm.get('min').reset()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Max Donation</mat-label>
    <input matInput type="number" formControlName="max">
    <button mat-icon-button matSuffix (click)="filterForm.get('max').reset()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<app-pagination item-name="Donors"></app-pagination>
<div fxLayout="row" fxLayoutAlign="start center">
  <div>
    <span class="bold">Total Count: </span>
    <span>{{totalRows | number: '1.0'}}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="totalPages > 0">
    <button mat-icon-button (click)="changePage(-1)" [disabled]="filterForm.get('pageIndex').value === 0">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div>
      <span>Page {{filterForm.get('pageIndex').value + 1}} of {{totalPages}}</span>
    </div>
    <button mat-icon-button (click)="changePage(1)" [disabled]="filterForm.get('pageIndex').value === totalPages - 1">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
<table class="donor-table">
  <thead>
    <tr>
      <th><span>Donor</span></th>
      <th class="center"><span>Total Pledges</span></th>
      <th class="center"><span>Total Paid</span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let donor of donorData">
      <td class="ellipsis"><span>{{donor.first_name + " " + donor.last_name}}</span></td>
      <td class="currency-cell"><span>{{donor.total_pledged}}</span></td>
      <td class="currency-cell"><span>{{donor.total_paid}}</span></td>
    </tr>
  </tbody>
</table>