<div fxFlexFill fxLayout="column" class="overflow-auto">
  <div *ngIf="showNavBar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="nav-bar">
    <button *ngIf="authSvc.isLoggedIn()" mat-icon-button
            color="accent" (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <div fxFlex></div>
    <app-search-bar *ngIf="authSvc.isLoggedIn()"></app-search-bar>
    <button mat-icon-button class="account-btn-icon">
      <mat-icon (click)="userProfileDialog()">account_circle</mat-icon>
    </button>
  </div>
  <mat-drawer-container fxFlex class="drawer-container overflow-auto" [hasBackdrop]="true">
    <mat-drawer #drawer mode="over" class="slg-sidebar-wrapper" fxLayout="column">
      <div *ngFor="let menuItem of menu"  class="list-group">
        <a [routerLink]="[menuItem.path]"
           class="list-group-item list-group-item-action">{{menuItem.label}}</a>
      </div>
    </mat-drawer>
    <mat-drawer-content fxFlexFill class="overflow-auto">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>