import { Component, OnInit, Inject } from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-email-preview-dialog',
  templateUrl: './email-preview-dialog.component.html',
  styleUrls: ['./email-preview-dialog.component.scss']
})
export class EmailPreviewDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public entryData: any
              ) { }

  ngOnInit(): void {
    
  }

}