import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { filter } from 'rxjs/operators';

import { ConfigService } from '@services/config.service';

export interface DonorFilter {
  name?: string;
  start?: string;
  end?: string;
  min?: number;
  max?: number;
  pageIndex: number;
  pageSize: number;
  orderBy: string;
}
export interface PlanningFilter {
  year: string;
  pageIndex: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class DonationsService {
  url: string;
  
  donationRecipientOptions: string[] = [];
  defaultRecipient: string = "";

  constructor(private http: HttpClient,
              private configSvc: ConfigService) {
    this.configSvc.settings.subscribe((settings: any) => this.url = settings.apiUrl);
    this.configSvc.settings.pipe(
      filter((settings) => settings.apiUrl)
    ).subscribe((settings: any) => {
      this.url = settings.apiUrl;
      this.donationRecipientOptions = settings.donations.recipientOptions;
      this.defaultRecipient = settings.donations.defaultRecipient;
    });
  }
  
  getAllDonations = () => {
    return this.http.get(this.url + "donations");
  }
  save = (donation: any) => {
    if (donation.donation_id) {
      return this.http.put(this.url + "donations", donation);
    } else {
      return this.http.post(this.url + "donations", donation);
    }
  }
  delete = (id: number) => {
    return this.http.delete(this.url + "donations", {params: {id: id}});
  }
  
  getFilteredDonors = (filter: DonorFilter) => {
    return this.http.post<any[]>(this.url + "donations/filter", filter);
  }
  getDonorsAndDonations = (filter: DonorFilter) => {
    return this.http.post(this.url + "donations/donors-and-donations", filter);
  }
  getFilteredDonations = (filter: any) => {
    return this.http.post(this.url + "donations/filtered", filter);
  }
  
  getAnnualPlanning = (filter: any) => {
    return this.http.get(this.url + "donations/annual-planning", {params: filter});
  }
  addDonorToAnnualPlanning = (id: number, filter: PlanningFilter) => {
    return this.http.post(this.url + "donations/annual-planning", filter, {params: {id: id}});
  }
  updateAnnualPlanningItem = (update: any, id: number, filter: PlanningFilter) => {
    let payload: any = {
      update: update,
      filter: filter
    }
    
    return this.http.put(this.url + "donations/annual-planning", payload, {params: {id: id}});
  }
  removeDonorFromAnnualPlanning = (id: number) => {
    return this.http.delete(this.url + "donations/annual-planning", {params: {id: id}});
  }
  
  fixAnnualPlanningRank = (year: string, quarter: string) => {
    return this.http.get(this.url + "donations/annual-planning-rank", {params: {year: year, quarter: quarter}});
  }
  updateAnnualPlanningRank = (payload: any) => {
    return this.http.put(this.url + "donations/annual-planning-rank", payload);
  }
  
  searchDonationTags = (text:string) => {
    return this.http.get(this.url + "donations/tag", {params: {text: text}});
  }
  createDonationTag = (tag: any) => {
    return this.http.post(this.url + "donations/tag", tag);
  }
  
  addDonationTag = (tagId: number, donationId: number) => {
    return this.http.post(this.url + "donations/link-tag", {donationId: donationId, tagId: tagId});
  }
  removeDonationTag = (joinId: number) => {
    return this.http.delete(this.url + "donations/link-tag", {params: {joinId: joinId}});
  }
  createAndLinkDonationTag = (tag: any, donationId: number) => {
    return this.http.post(this.url + "donations/create-and-link-tag", tag, {params: {donationId: donationId}});
  }
} 